$(document).ready(function () {
	/* Owl Carousel for mobile devices */
	$(window).resize(function () {
		if ($(window).width() < 800) {
			$('.statistics__list').addClass('owl-carousel owl-theme').owlCarousel({
				items : 1,
				singleItem : true
			});
		}
	});
	$(window).resize();
});
