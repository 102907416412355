$(document).ready(function() {
	/* Certificates popup */
	$('.fancybox.certificates__box').fancybox({
		aspectRatio: true,
		scrolling: 'no',
		minWidth: 846
	});

	/* jQuery UI Slider */
	//scrollpane parts
	var scrollPane = $( ".certificates__pane" ),
		scrollContent = $( ".certificates__body" );
 
	//build slider
	var scrollbar = $( ".certificates__userbar" ).slider({
		slide: function( event, ui ) {
			if ( scrollContent.width() > scrollPane.width() ) {
				scrollContent.css( "margin-left", Math.round(
					ui.value / 100 * ( scrollPane.width() - scrollContent.width() )
				) + "px" );
			} else {
			  scrollContent.css( "margin-left", 0 );
			}
		}
	});

	//certificates body width calculation
	var items = $('.certificates__item').length,
		itemWidth = $('.certificates__item').width(),
		totalWidth = items * itemWidth;

	scrollContent.width(totalWidth);
});

