$(document).ready(function () {
	/* MENU FOR MOBILE */
	$('.header__hamburger').click(function () {
		$('.mobmenu').fadeIn(400);
		$(this).addClass('header__hamburger_hidden');
	});
	$('.mobmenu__close').click(function () {
		$('.mobmenu').fadeOut(400);
		$('.header__hamburger').removeClass('header__hamburger_hidden');
	});
});