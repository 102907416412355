$(document).ready(function() {
	/* Default popup */
	$('.fancybox.btn').fancybox({
		nextEffect	: 'none',
		prevEffect	: 'none',
		helpers: {
			overlay: {
				locked: false
			}
		}
	});
});

