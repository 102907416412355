$(document).ready(function () {
	/* Slick slider run */
	$('.customers__slider').slick({

		// general options
		autoplay: true,
		autoplaySpeed: 4000,
		arrows: false,
		slidesToShow: 5,
		slidesToScroll: 1,

		// responsive settings
		responsive: [{

			breakpoint: 1040,
			settings: {
				slidesToShow: 3
			}

		}, {

			breakpoint: 640,
			settings: {
				slidesToShow: 1
			}

		}]
	});
});