$(document).ready(function () {
	/* Articles items switch */
	$('.articles-list_js').on('click', '.articles-list__top', function () {
		$(this)
			.parents('.articles-list__item').toggleClass('articles-list__item_open')
			.find('.articles-list__bottom').slideToggle('300');
		$(this)
			.parents('.articles-list__item')
			.siblings().removeClass('articles-list__item_open')
			.find('.articles-list__bottom').slideUp('300');
	});
	/* Open item if there is only one item on page */
	var items = $('.articles-list__item').length;

	if (items == 1) {
		$('.articles-list_js .articles-list__item')
			.addClass('articles-list__item_open')
			.find('.articles-list__bottom').slideToggle('300');
	} else if (items === 0) {
		$('.empty').show();
	}

	/* Main page news anchors and news page items connection */
	var params = {};
    window.location.search
        .replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str,key,value) {
            params[key] = value;
        }
    );

    if (params.item) {
        $(".articles-list__top[data-list-item='"+ params.item +"']").click();
    }
});
