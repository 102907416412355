$(document).ready(function () {
	/* Slick slider run */
	$('.history__background').slick({
		infinite: false,
		asNavFor: '.history__content'
	});

	$('.history__content').slick({
		infinite: false,
		arrows: false,
		asNavFor: '.history__background',
		swipe: false
	});

	/* History chronology scale switch */
	$(".history__chronology").on("click", ".history__point", function() {
		var b = $(this),
			c = b.data("slide");

		$(".history__point.is-active").removeClass("is-active");
		b.addClass("is-active");
		$(".history__content").slick("slickGoTo", c, !1);
	});
	$(".slick-arrow").click(function() {
		var b = $(".history__content").slick("slickCurrentSlide");

		$(".history__point.is-active").removeClass("is-active");
		$('.history__point[data-slide="' + b + '"]').addClass("is-active");
	});
});