$(document).ready(function () {
	/* OWL CAROUSEL */
	$('.article-block__slider').owlCarousel({
		items : 1,
		singleItem : true,
		navigation : true,
		navigationText: false,
		autoHeight : false
	});

	/* Adaptive left col content sliding */
	if ($(window).width() < 768) {
		$('.article-block__caption').removeClass('desktop');

		if ($('.article-block__caption').hasClass('desktop')) {
			$('.article-block__short-info').on('click', this, function () {
				return false;
			});
		} else {
			$('.article-block__short-info').on('click', this, function () {
				$('.article-block__body').slideToggle(300);
			});
		}
	} else {
		$('.article-block__caption').addClass('desktop');
	}

	/* Partner one title height matching */
	$('.article-block__goto').matchHeight();

	/* Open files and outer links in new tab */
	$('.article-block__links-target, .article-block__anchor').click(function () {
		window.open(this.href);
		return false;
	});
});