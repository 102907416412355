$(document).ready(function () {
	/* Banner columns height matching */
	$('.banner__col').matchHeight();
	$('.banner__col_left').matchHeight();

	/* OWL CAROUSEL */
	$('.banner__wrapper').addClass('owl-carousel owl-theme').owlCarousel({
		items : 1,
		singleItem : true,
		navigation : true,
		navigationText: false,
		autoHeight : false,
		autoPlay: true
	});
});
