$(document).ready(function() {
	// Backstretch bg image slider call
	$(window).resize(function(){
		var backstrOpts = {duration: 7e3, fade: 750};

		if ($('body').width() > 767) {
			$(".header__backstretch").backstretch(sliderPhotos, backstrOpts);
		} else {
			$(".header__backstretch").backstretch(sliderPhotosMob, backstrOpts);
		}
	}).trigger('resize');

	// Backstretch prev/next triggers
	$('.header__arrow_prev').click(function() {
		$('.header__backstretch').backstretch("prev");
	});

	$('.header__arrow_next').click(function() {
		$('.header__backstretch').backstretch("next");
	});

	/* Header top background handler */
	var header = $('.header__top'),
        animation = true;

    $(window).resize(function(){
        animation = ($('body').width() <= 767) ? false : true;

        if (!animation) {
            if (!header.hasClass('header__top_bg')) {
                header.addClass('header__top_bg');
            }
        } else {
            if (header.hasClass('header__top_bg')) {
                header.removeClass('header__top_bg');
            }
        }

        headerBg();
    }).trigger('resize');
        

	$(window).scroll(function(){
        headerBg();

        /* Hero slider bg animation on scroll */
        var winTop = $(window).scrollTop(),
            headerHeight = $('.header').outerHeight(),
            scrollBlackout = ((100 - ((winTop/headerHeight)*125))/100).toFixed(3),
            scaleValue = ((100 - ((winTop/headerHeight)*15))/100).toFixed(3),
            scaleDepth = ((winTop/headerHeight)*-0.0001).toFixed(6);
            scrollScale = "matrix3d(" + scaleValue + ",0,0,0,0," + scaleValue + ",0," + scaleDepth + ",0,0,1,0,0,0,0,1)";

        $(".header__backstretch").css({
            "opacity": scrollBlackout,
            "transform": scrollScale
        });
    }).trigger('scroll');

    function headerBg() {
        if ($(window).scrollTop() > 25) {
            if (!header.hasClass('header__top_bg')) {
                header.addClass('header__top_bg');
            }
        } else {
            if (header.hasClass('header__top_bg')) {
                header.removeClass('header__top_bg');
            }
        }
    }
});
