$(document).ready(function () {
	/* Partners tiles hover class switch */
	if ($(window).width() > 1024) {
		$('.partners__tile').mouseover(function () {
			$(this).addClass('hover');
		});
		$('.partners__tile').mouseout(function () {
			$(this).removeClass('hover');
		});
	}

	$(window).resize(function() {
		var brandTile = $('.partners__tile'),
			bTHeight = parseInt(brandTile.width() / 1.77);

		if ($(window).width() < 480) {
			brandTile.height(bTHeight);
		} else {
			brandTile.height(330);
		}
	}).trigger('resize');
});