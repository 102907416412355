$(document).ready(function(){
    elem = 'map';

    $('.map-tabs__trigger').click(function(event) {
        event.preventDefault();
        $(this).siblings('.map-tabs__show').trigger('click');
    });

    $('.map-tabs__show').click(function(event) {
        event.preventDefault();
        $('.map-tabs__show').removeClass('active');
        $('.map-tabs__show').siblings('.map-tabs__trigger').removeClass('active');
        $(this).addClass('active');
        $(this).siblings('.map-tabs__trigger').addClass('active');

        var formInfo = $(this).siblings('form.info'),
            formMarker = $(this).siblings('form.marker'),
            markers = [],
            options = {
                info: '',
                markers: []
            };

        formInfo.each(function() {
            var data = {};

            data.center = $('.center', $(this)).val().split(',');
            data.center[0] = parseFloat(data.center[0]);
            data.center[1] = parseFloat(data.center[1]);
            data.zoom = parseInt($('.zoom', $(this)).val());

            options.info = data;
        });

        formMarker.each(function() {
            var data = {};

            data.title = $('.title', $(this)).val();
            data.city = $('.city', $(this)).val();
            data.coords = $('.coords', $(this)).val().split(',');
            data.coords[0] = parseFloat(data.coords[0]);
            data.coords[1] = parseFloat(data.coords[1]);
            data.content = $('.content', $(this)).val();

            markers.push(data);
        });

        options.markers = markers;

        mapCreate (options);
    });

    if ($('.map').length) {
        $('.map-tabs__trigger').eq(0).trigger('click');
    }

    function mapCreate (options) {
        var image = 'images/map-marker.png';

        var styles = [
            {
                stylers: [
                    { visibility: "on" },
                    { lightness: 0 },
                    { saturation: -100 },
                    { gamma: 1 }
                ]
            },{
                featureType: "road",
                elementType: "geometry",
                stylers: [
                    { lightness: 100 }
                ]
            },{
                featureType: "road",
                elementType: "geometry.stroke",
                stylers: [
                    { color: "#cccccc" }
                ]
            },{
                featureType: "road.arterial",
                elementType: "geometry",
                stylers: [
                    { color: "#bfbfbf" }
                ]
            },{
                featureType: "road.arterial",
                elementType: "geometry.stroke",
                stylers: [
                    { color: "#a5a5a5" }
                ]
            },{
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [
                    { color: "#999999" }
                ]
            },{
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [
                    { color: "#747474" }
                ]
            }
        ];

        var map = new google.maps.Map(document.getElementById(elem), {
            zoom: options.info.zoom,
            center: new google.maps.LatLng(options.info.center[0], options.info.center[1]),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false,
            mapTypeControl: false,
            zoomControl: true,
            panControl: true,
            styles: styles
        });

        var infowindow = new google.maps.InfoWindow();

        var marker, i;

        for (i = 0; i < options.markers.length; i++) { 
            var title = options.markers[i].city,
                lat = options.markers[i].coords[0],
                long = options.markers[i].coords[1],
                string = '<div class="map-tabs__overlay"><div class="map-tabs__head">'+ options.markers[i].title +'</div><h5 class="map-tabs__trigger">'+ options.markers[i].city +'</h5><p class="map-tabs__address">'+ options.markers[i].content +'</p></div>';

            marker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, long),
                icon: image,
                map: map
            });

            google.maps.event.addListener(marker, 'click', (function(marker, i, string) {
                return function() {
                    infowindow.setContent(string);
                    infowindow.open(map, marker);
                };
            })(marker, i, string));
        }
    }
});

