$(document).ready(function () {
	/* bxSlider call */
	// $('.reviews__list').bxSlider({
	// 	mode: 'vertical',
	// 	minSlides: 5,
	// 	pager: false,
	// 	moveSlides: 1,
	// 	infiniteLoop: false,
	// 	hideControlOnEnd: true
	// });

	// $('.reviews__body').on('click', '.reviews__cell', function () {
	// 	var number = $(this).parent().attr('data-num');

	// 	$(this)
	// 		.parent().addClass('active')
	// 		.siblings().removeClass('active');

	// 	$('.reviews__container')
	// 		.eq(number).fadeIn(300)
	// 		.siblings().hide();
	// });

	/* Reviews popup */
	$('.fancybox.reviews__box').fancybox({
		aspectRatio: true,
		scrolling: 'no',
		minWidth: 847
	});

	/* jQuery UI Slider */
	//scrollpane parts
	var scrollPane = $( ".reviews__pane" ),
		scrollContent = $( ".reviews__body" );
 
	//build slider
	var scrollbar = $( ".reviews__userbar" ).slider({
		slide: function( event, ui ) {
			if ( scrollContent.width() > scrollPane.width() ) {
				scrollContent.css( "margin-left", Math.round(
					ui.value / 100 * ( scrollPane.width() - scrollContent.width() )
				) + "px" );
			} else {
			  scrollContent.css( "margin-left", 0 );
			}
		}
	});

	//Reviews body width calculation
	var items = $('.reviews__item').length,
		itemWidth = $('.reviews__item').width(),
		totalWidth = items * itemWidth;

	scrollContent.width(totalWidth);
});
